.cart-page-container-desktop {
  & .cart-content-container-desktop {
    & .cart-food-container {
      & .back-to-menu-cart-page {
        font-family: $National2Medium;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        //text-decoration-line: underline;
        color: #202124;
        margin-top: -16px;
        margin-bottom: 14px;
        display: flex;
        justify-content: space-between;
      }
      .ItemUnavailable-remove-section {
        display: inline-block;
        .cart-removeall-content-parent-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .removeall-cart-text-container {
            .cart-removeall-text-header {
              margin-bottom: 8px;
              font-family: $National2Medium;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 150%;
              color: #202124;
              margin-left: 0 !important;
            }

            .cart-removeall-text-content {
              font-family: $National2Regular;
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 150%;
              letter-spacing: -0.1px;
              color: #202124;
              margin-left: 0 !important;
            }
          }

          .cart-removeall-button {
            min-width: 4.3rem;
            font-family: $National2Medium;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 150%;
            height: 32px !important;
            justify-content: center;
            align-items: center;
            padding: 0px;
            color: #202124;
            border: 1px solid #202124 !important;
            border-radius: 16px;
            background-color: #f8f7f5;
          }
        }
      }

      .back-to-menu-cart-page a {
        text-decoration-line: underline;
      }
      .cart-promos-signin {
        border-bottom: 1.5px solid;
      }
      & .outofstock.cart-highlight {
        & .cart-food-content-parent-container {
          & .cart-food-card-customise-details {
            & .cart-food-card-details {
              display: none;
            }
          }
        }
      }
      & .cartEditMessage-container {
        background: #e6f3e6;
        height: 54px;
        padding-top: 15px;
        padding-left: 20px;
        & img {
          width: 15px;
          height: 15px;
        }
        & .cartEditMessage {
          font-family: $National2Regular;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: -0.1px;
          color: #202124;
          padding-left: 10px;
        }
      }
      & .cart-food-item-container {
        & .cart-food-content-parent-container {
          margin-left: 6.5%;
          width: 100%;
          position: relative;
          display: flex;
          flex-direction: column;
          margin-top: 15px;

          & .cart-food-card-item-name {
            font-family: $National2Medium;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            color: $Brand-Black;
          }

          & .cart-food-card-customise-details {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            & .cart-food-card-customised {
              & .global-accordian-header {
                font-family: $National2Regular;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 22px;
                color: $Dark-Gray;
                padding-right: 10px;
              }

              & .cart-food-modifier {
                margin-left: -25px;
                font-family: $National2Regular;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 24px;
                letter-spacing: -0.1px;
                color: $Dark-Gray;

                ul > ul {
                  padding-left: 20px;
                  & .subModifier {
                    list-style: disc;
                  }
                }
              }
              & .global-accordian-container {
                border: none;
                margin-bottom: 0px;
                min-height: 0px;

                &:focus {
                  outline: none;
                }

                .global-accordian-header-container {
                  padding: 19px;
                  padding-top: 0;
                  padding-left: 0;
                  height: 30px;
                  justify-content: unset;

                  img {
                    padding-top: 3px;
                  }
                  .global-accordian-arrow {
                    width: 8.82px !important;
                    height: 8.82px !important;
                  }
                }

                .global-accordion-subheader {
                  padding-left: 15px;
                  margin: 0;
                }
                & .global-accordion-subheadertext {
                  &.custom-tag {
                    display: none;
                  }
                }
              }
              & .nonCustomizable-foodName {
                display: none;
              }
            }
          }
          & .cart-coupon-text {
            color: $Brand-Red;
            margin: 5px;
            text-transform: capitalize;
          }
          & .cart-food-card-customise-details .cart-coupon-link {
            display: none;
          }
          & .cart-food-card-details {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            & .cart-food-card-custom-tag {
              font-family: $National2Condensed;
              font-style: normal;
              font-weight: bold;
              font-size: 16px;
              display: flex;
              align-items: center;
              text-align: center;
              letter-spacing: 1px;
              text-transform: uppercase;
              color: #ffffff;
              background: #000000;
              border-radius: 30px;
              width: 100%;
              height: 20px;
              padding-left: 10px;
              padding-right: 10px;
              margin-top: 6px;
            }
            & .cart-food-card-quantity-modifier {
              & .cart-food-quantity-price {
                position: unset;

                & .cart-food-quantity-change {
                  margin-left: 20px;
                  margin-right: 3px;
                }
              }
            }
          }

          @media #{$breakpoint-ipad-12-pro-portrait} {
            & .cart-food-card-customise-details {
              display: flex;
              flex-direction: row;
              justify-content: space-between;

              & .cart-food-card-customised {
                & .global-accordian-header {
                  font-family: $National2Regular;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 12px;
                  line-height: 15px;
                  color: $Dark-Gray;
                  padding-right: 5px;
                }

                & .cart-food-modifier {
                  margin-left: -25px;
                  font-family: $National2Regular;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 14px;
                  line-height: 24px;
                  letter-spacing: -0.1px;
                  color: $Dark-Gray;

                  ul > ul {
                    padding-left: 20px;
                    & .subModifier {
                      list-style: disc;
                    }
                  }
                }
                & .global-accordian-container {
                  border: none;
                  margin-bottom: 0px;
                  min-height: 46px;

                  &:focus {
                    outline: none;
                  }

                  .global-accordian-header-container {
                    padding: 19px;
                    padding-top: 0;
                    padding-left: 0;
                    height: 30px;
                    justify-content: unset;

                    img {
                      padding-top: 3px;
                    }
                    .global-accordian-arrow {
                      width: 8.82px !important;
                      height: 8.82px !important;
                    }
                  }

                  .global-accordion-subheader {
                    padding-left: 15px;
                    margin: 0;
                  }
                  & .global-accordion-subheadertext {
                    &.custom-tag {
                      display: none;
                    }
                  }
                }
                & .nonCustomizable-foodName {
                  display: none;
                }
              }
            }

            & .cart-food-card-details {
              display: flex;
              flex-direction: row;
              justify-content: space-between;

              & .cart-food-card-custom-tag {
                font-family: $National2Condensed;
                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                display: flex;
                align-items: center;
                text-align: center;
                letter-spacing: 1px;
                text-transform: uppercase;
                color: #ffffff;
                background: #000000;
                border-radius: 30px;
                width: 55px;
                height: 20px;
                padding-left: 5px;
                margin-top: 6px;
              }
              & .cart-food-card-quantity-modifier {
                & .cart-food-quantity-price {
                  position: unset;

                  & .cart-food-quantity-change {
                    margin-left: 10px;
                    margin-right: 5px;
                    & .quantity-increase {
                      height: 27px;
                      width: 27px;
                    }
                    & .quantity-decrease {
                      height: 27px;
                      width: 27px;
                    }
                  }
                }
              }
            }
          }
        }
        & .cart-food-card-quantity-modifier-mobile {
          display: none;
        }
        & .cart-food-card-item-edit-remove {
          font-family: $National2Medium;
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 22px;
          text-decoration-line: underline;
          color: $Brand-Black;
        }

        & .cart-outofstock-text {
          width: 100%;
          display: flex;
          margin: auto;
          font-family: $National2Medium;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: #ff2e00;
          justify-content: end;
          img {
            margin-right: 5px;
          }
        }
      }
    }
  }
  @media #{$breakpoint-ipad-12-pro-portrait} {
    .cart-empty-root {
      & .cart-empty-placeholder-view {
        margin-bottom: 63%;
        & .cart-empty-placeholder-text {
          width: 85%;
        }
      }

      & .cart-empty-img {
        position: absolute;
        margin-left: 7%;
        margin-top: 25%;
        padding-top: 0px;
      }
    }
  }

  @media #{$breakpoint-samsung-galaxy-tab-s7-landscape} {
    .cart-empty-root {
      & .cart-empty-placeholder-view {
        margin-bottom: 63%;
        & .cart-empty-placeholder-text {
          width: 73%;
        }
      }

      & .cart-empty-img {
        position: absolute;
        margin-top: 25%;
        margin-left: 9%;
      }
    }
  }
}
.paper-bag-fee {
  flex-direction: row !important;
  & .cart-food-card-item-name {
    margin-bottom: 25px !important;
  }
  & .cart-food-card-customise-details {
    margin-left: auto !important;
  }
}
.get-paper-bag-fee {
  & .cart-food-price {
    margin-left: auto;
  }
}
@media #{$breakpoint-below-tablet} {
  .cart-page-container-mobile {
    & .cart-content-container-mobile {
      & .cart-food-container {
        & .back-to-menu-cart-page {
          font-family: $National2Medium;
          font-style: normal;
          font-weight: normal;
          font-size: 11px;
          line-height: 22px;
          //text-decoration-line: underline;
          color: #202124;
          margin-left: 20px;
          margin-right: 20px;
          margin-bottom: 22px;
        }
        .back-to-menu-cart-page a {
          text-decoration-line: underline;
          font-size: 11px;
        }
        .cart-removeall-content-parent-container {
          .removeall-cart-text-container {
            float: left;
            .cart-removeall-text-header {
              width: 204px;
              height: 21px;
              font-family: $National2Medium;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 150%;
              color: #202124;
              padding-bottom: 8px;
            }
            .cart-removeall-text-content {
              width: 185px;
              height: 42px;
              font-family: $National2Regular;
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 150%;
              letter-spacing: -0.1px;
              color: #202124;
            }
          }
          .cart-removeall-button {
            min-width: 76px;
            height: 32px;
            font-family: $National2Medium;
            float: right;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 0px;
            margin-top: 4%;
            color: #202124;
            border: 1px solid #202124;
            border-radius: 16px;
            background-color: #f8f7f5;
          }
        }
        & .outofstock.cart-highlight {
          & .cart-food-content-parent-container {
            & .cart-food-card-customise-details {
              & .cart-food-card-customised {
                & .cart-outofstock-text {
                  display: none;
                }
                & .global-accordian-container {
                  & .global-accordion-subheadertext.custom-tag {
                    display: none;
                  }
                }
              }
            }
            & .cart-food-card-item-edit-remove {
              margin-top: 30px;
              span {
                margin-left: 0px;
              }
            }
          }
          & .cart-outofstock-text {
            display: none;
          }
        }
        & .cartEditMessage-container {
          background: #e6f3e6;
          height: 54px;
          padding-top: 15px;
          padding-left: 20px;
          margin: -10px 20px 15px 20px;
          & img {
            width: 15px;
            height: 15px;
          }
          & .cartEditMessage {
            font-family: $National2Regular;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: -0.1px;
            color: #202124;
            padding-left: 10px;
          }
        }

        @media screen and (min-width: 357px) and (max-width: 400px) {
          & .cartEditMessage-container {
            background: #e6f3e6;
            height: 54px;
            padding-top: 15px;
            padding-left: 10px;
            margin: -10px 20px 15px 20px;
            & img {
              width: 15px;
              height: 15px;
            }
            & .cartEditMessage {
              font-family: $National2Regular;
              font-style: normal;
              font-weight: normal;
              font-size: 13px;
              line-height: 24px;
              letter-spacing: -0.1px;
              color: #202124;
              padding-left: 5px;
            }
          }
        }
        @media screen and (min-width: 320px) and (max-width: 356px) {
          & .cartEditMessage-container {
            padding-top: 0px;
            & .cartEditMessage {
              line-height: 16px;
            }
          }
        }
        & .cart-food-item-container {
          &.cart-card-mobile {
            display: flex;
            padding-left: 12px;
            padding-right: 12px;
            height: -moz-fit-content;
            height: fit-content;
            padding-top: 20px;
            padding-bottom: 18px;
            background-color: #f8f7f5;
            flex-direction: column;
            flex-direction: row;
            flex-wrap: wrap;
            margin: 10px 10px;
          }

          & .cart-food-content-parent-container {
            padding-left: 20px;
            width: 100%;
            flex: 50%;
            & .cart-food-card-item-name {
              @media #{$mobile-only} {
                font-family: $National2Medium;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 20px;
              }
            }

            & .cart-food-card-customise-details {
              & .cart-food-card-customised {
                & .nonCustomizable-foodName,
                & .global-accordian-header {
                  font-family: $National2Medium;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 14px;
                  line-height: 20px;
                  color: $Brand-Black;
                  @media #{$mobile-only} {
                    color: #494949;
                    font-size: 12px;
                  }
                }
                & .global-accordian-container {
                  & .global-accordian-header-container {
                    height: auto;
                    & .global-accordian-arrow {
                      width: 8.82px !important;
                      height: 8.82px !important;
                    }
                  }
                  & .global-accordion-subheadertext {
                    &.custom-tag {
                      font-family: $National2Condensed;
                      font-style: normal;
                      font-weight: bold;
                      font-size: 8px;
                      display: flex;
                      align-items: center;
                      text-align: center;
                      letter-spacing: 1px;
                      text-transform: uppercase;
                      color: #ffffff;
                      background: #000000;
                      border-radius: 30px;
                      width: 68px;
                      height: 20px;
                      padding-left: 7px;
                      margin-top: 6px;
                    }
                  }
                }
                & .cart-food-modifier {
                  margin-left: -25px;
                  font-family: $National2Regular;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 14px;
                  line-height: 24px;
                  letter-spacing: -0.1px;
                  color: $Dark-Gray;

                  ul > ul {
                    padding-left: 20px;
                    & .subModifier {
                      list-style: disc;
                    }
                  }
                }
                display: flex;
              }
            }
            & .cart-coupon-text {
              color: $Brand-Red;
              margin: 5px;
              font-weight: 600;
              font-family: $National2Medium;
              font-style: normal;
              font-size: 14px;
              line-height: 20px;
              text-transform: capitalize;
            }
            & .cart-food-card-details {
              & .cart-food-card-custom-tag {
                display: none;
              }

              & .cart-food-card-quantity-modifier {
                display: none;
              }
            }
            & .cart-food-card-item-edit-remove {
              font-family: $National2Medium;
              font-style: normal;
              font-weight: bold;
              font-size: 12px;
              line-height: 22px;
              text-decoration-line: underline;
              color: $Brand-Black;

              & .cart-food-edit {
                margin-right: 20px;
              }
            }
          }
          & .cart-food-card-quantity-modifier-mobile {
            width: 100%;
            & .cart-outofstock-text {
              display: flex;
              justify-content: center;
              font-family: $National2Medium;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 20px;
              letter-spacing: -0.1px;
              color: $Brand-Red;

              img {
                margin-right: 5px;
              }
            }
          }
        }
      }
    }
  }
}

@media #{$breakpoint-tablet-only} {
  .cart-content-container-mobile {
    & .cart-empty-placeholder-view {
      padding-right: 47%;
      @media #{$breakpoint-samsung-galaxy-tab-s7} {
        padding-right: 48%;
      }
    }
    & .cart-empty-img {
      position: absolute;
      @media #{$breakpoint-samsung-galaxy-tab-s7} {
        position: relative;
        margin-right: 58%;
      }
    }
  }

  @media #{$breakpoint-samsung-galaxy-tab-s7} {
    .cart-empty-root {
      & .cart-empty-placeholder-view {
        padding-left: 2.3%;
        padding-right: 52%;
        & .cart-empty-placeholder-button {
          margin-left: 34.5px;
        }
      }
    }
  }
}

@media screen and (min-width: 357px) and (max-width: 400px) {
  .cart-empty-root {
    & .cart-empty-placeholder-view {
      @media #{$breakpoint-samsung-s20} {
        padding-left: 2.5%;
        padding-right: 0;
      }
      @media #{$breakpoint-iphone-12-pro-portrait} {
        padding-left: 20px;
        padding-right: 0px;
      }
      & .cart-empty-placeholder-text {
        margin-left: 42px;
        margin-right: 19px;
        @media #{$breakpoint-googlepixel-5} {
          margin-left: 53px;
          width: 75%;
        }
        @media #{$breakpoint-iphone-11-pro-portrait} {
          width: 88%;
          margin-left: 51px;
        }
        @media #{$breakpoint-iphone13} {
          margin-left: 53px;
        }
        @media #{$breakpoint-samsung-s21plus} {
          width: 75%;
          margin-left: 49px;
          margin-right: 19px;
        }
        @media #{$breakpoint-iphone-12-pro-portrait} {
          width: 78%;
          margin-left: 52px;
          margin-right: 19px;
        }
      }

      & .cart-empty-placeholder-button {
        @media #{$breakpoint-googlepixel-5} {
          margin-left: 51.5px;
        }
        @media #{$breakpoint-iphone-11-pro-portrait} {
          margin-left: 50.5px;
        }
        @media #{$breakpoint-iphone13} {
          margin-left: 53px;
        }
        @media #{$breakpoint-samsung-s20} {
          margin-left: 42.5px;
        }
        @media #{$breakpoint-samsung-s21plus} {
          margin-left: 46.5px;
        }
        @media #{$breakpoint-iphone-12-pro-portrait} {
          margin-left: 40px;
        }
      }
    }
  }
}

.viewall-promos-cart-page {
  @media #{$breakpoint-below-tablet} {
    display: flex;
    justify-content: space-between;
    margin-right: 5px;
  }
  & .view-all-text {
    display: flex;
    & .offersViewAll-link {
      font-family: $National2Medium;
      font-weight: 500;
      margin-right: 5px;
      cursor: pointer;
    }
  }
}

.cart-food-item-container .cart-coupon-link {
  display: flex;
  margin-top: -5px;
}
.cart-food-item-container .cart-offers-icon {
  margin-top: 5px;
  @media #{$breakpoint-below-tablet} {
    margin-top: 0px;
  }
}

.cart-food-item-container {
  display: flex;
}

.cart-food-card-item-name {
  display: flex;
  @media #{$breakpoint-below-tablet} {
    display: none;
  }
}
.cart-food-card-quantity-modifier .promos-summary-cart-notification {
  position: absolute;
  width: 275px;
  right: -15px;
  top: -77px;
}
.cart-food-card-quantity-modifier-mobile .promos-summary-cart-notification {
  position: relative;
  width: 100%;
  margin-left: 0px;
  margin-top: -110px;
}
.Toastify {
  & .Toastify__toast-container {
    & .addon-removed-toast {
      background-color: #fff4f2;
      box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.15);
      border-radius: 3px;
      width: 498px;
      height: 54px;
      @media #{$breakpoint-below-tablet} {
        width: 100%;
      }
      & .img-text-div {
        display: flex;
        flex-direction: row;
        position: relative;
        & .text {
          color: #202124;
          letter-spacing: -0.1px;
          line-height: 24px;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          font-family: $National2Regular;
          padding-left: 5px;
        }
      }
    }
  }
}
.coupon-button {
  &:hover {
    &::after {
      opacity: 1;
    }
  }
}
